import clearGridLogo from "../../data/assets/logos/clearGridLogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSidebarState,
  setSidebarOpen,
} from "../../data/store/slices/utilSlice";
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useState } from "react";
import { logOut } from "../../data/store/slices/authSlice";
import { useNavigate } from "react-router-dom";

export interface ITopbarProps {}

export default function Topbar(props: ITopbarProps) {
  const lenderName = localStorage.getItem("lenderName");
  const currentSidebarState = useSelector(selectCurrentSidebarState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="flex justify-between py-3 px-8 border-b-2 bg-[#FBFCFE] w-full">
      <div className="content-center lg:hidden">
        <MenuIcon
          onClick={(): any =>
            dispatch(setSidebarOpen({ sidebarOpen: !currentSidebarState }))
          }
        />
      </div>
      <img className={"w-32"} src={clearGridLogo} alt="ClearGrid_logo" />
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar>{lenderName?.charAt(0).toUpperCase()}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => navigate("/profile-settings")}>
          <Avatar /> Profile
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            dispatch(logOut());
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            navigate(`/l/${lenderName}`);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
