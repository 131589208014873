import { useLocation } from "react-router-dom";
import DetailsHeader from "../../../../components/borrowerComponents/DetailsHeader";
import { useEffect, useState } from "react";
import { getAccount } from "../../../../data/api/accountAPI";
import { AccountDetail } from "../../../../data/models/types/Account";
import { useDispatch } from "react-redux";
import CTabs, { Tab } from "../../../../components/tabs/CTabs";
import AccountDetails from "./accountDetails";
import AccountDisputes from "./accountDisputes";
import notification from "../../../../utils/toast";
import { setLoadingWithDelay } from "../../../../data/store/thunk/utilThunk";
import { setLoading } from "../../../../data/store/slices/utilSlice";
import { createRequest } from "../../../../data/api/fetch";

interface IAccount {}

const Account = (props: IAccount) => {
  const lender: any = localStorage.getItem("lenderName");
  const currency: string | null = localStorage.getItem("currency");
  const [account, setAccount] = useState<AccountDetail>();
  const dispatch: any = useDispatch();
  const { state } = useLocation();

  const detailHeaderAttributes = [
    {
      name: "Balance Amount",
      value: account?.balanceAmount
        ? `${currency} ` + account?.balanceAmount
        : "-",
      bold: true,
    },
    {
      name: "Amount Paid",
      value: account?.paidAmount ? `${currency} ` + account?.paidAmount : "-",
      bold: true,
    },
    {
      name: "Payment Methods",
      value:
        account?.paymentMethod && account?.paymentMethod.length > 0
          ? account?.paymentMethod[0].last4 +
            " " +
            account?.paymentMethod[0].expMonth +
            "/" +
            account?.paymentMethod[0].expYear
          : "-",
    },
    {
      name: "Ref ID",
      value: account?.referenceId
        ? account?.referenceId.toLocaleUpperCase()
        : "-",
      bold: true,
    },
  ];

  useEffect(() => {
    getAccountById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountById = async () => {
    try {
      dispatch(setLoading(true));
      const path: string = `lenders/${lender}/borrowers/${state.borrowerid}/accounts/${state.accountId}`;

      // const res: any = await getAccount({
      //   lender,
      //   accountId: state.accountId,
      //   borrowerId: state.borrowerid,
      // });

      // if (res) setAccount(res.borrowerAccount);
      const res = await createRequest({
        path: `${path}`,
        method: "GET",
      });

      if (res.ok) {
        const { body } = await res.json();
        setAccount(body.borrowerAccount);
      }
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoading(false));
    }
  };

  const tabs: Tab[] = [
    {
      label: "Accounts",
      content: (
        <AccountDetails account={account} getAccountById={getAccountById} />
      ),
    },
    {
      label: "Disputes",
      content: <AccountDisputes account={account} />,
    },
  ];

  return (
    <div>
      <DetailsHeader
        borrowerName={account?.fullName}
        properties={{ type: "account", attributes: detailHeaderAttributes }}
        showStatus
        status={account?.status!}
      />
      <CTabs tabs={tabs} />
    </div>
  );
};

export default Account;
