import React, { useEffect, useRef } from "react";

type Props = {
  token: string;
};

const TableaueEmbed = ({ token }: Props) => {
  const vizContainer = useRef<HTMLDivElement>(null);
  const lenderName = localStorage.getItem("lenderFullName");

  useEffect(() => {
    // Ensure the Tableau script is loaded
    const script = document.createElement("script");
    script.type = "module";
    script.src =
      "https://eu-west-1a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";
    script.onload = () => {
      if (vizContainer.current) {
        // Create the Tableau Viz
        const vizElement = document.createElement("tableau-viz");
        const vizParameter = document.createElement("viz-parameter");
        vizParameter.setAttribute("name", "lender");
        vizParameter.setAttribute("value", lenderName || "");
        vizElement.appendChild(vizParameter);

        vizElement.setAttribute("id", "tableau-viz");
        vizElement.setAttribute(
          "src",
          "https://eu-west-1a.online.tableau.com/t/cleargrid/views/BorrowerTrails/BorrowerTrails"
        );

        vizElement.setAttribute("width", "100%");
        vizElement.setAttribute("height", "840");
        vizElement.setAttribute("hide-tabs", "");
        vizElement.setAttribute("toolbar", "bottom");
        vizElement.setAttribute("hide-edit-button", "");
        vizElement.setAttribute("token", token);

        vizContainer.current.appendChild(vizElement);
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      if (vizContainer.current) {
        vizContainer.current.innerHTML = "";
      }
    };
  }, []);

  return <div ref={vizContainer} />;
};

export default TableaueEmbed;
