import { Form, Formik } from "formik";
import { payInFullSchema } from "../../../../data/validations/ValidationScemas";
import SwitchField from "../../../../components/formComponents/fieldComponents/SwitchField";
import {
  getAccountConfig,
  putAccountConfig,
} from "../../../../data/api/accountAPI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setOpenModal,
} from "../../../../data/store/slices/utilSlice";
import notification from "../../../../utils/toast";
import { setLoadingWithDelay } from "../../../../data/store/thunk/utilThunk";

interface IPayInFull {
  amount?: number;
  accountId: string;
  lender: string;
  borrowerId: string;
}

interface IPayInFullConfig {
  allowed: boolean;
  redirectionLink: string;
  redirectionAllowed: boolean;
}

const PayInFull = ({ lender, accountId, borrowerId, amount }: IPayInFull) => {
  const [configs, setConfigs] = useState<IPayInFullConfig | null>(null);
  const dispatch: any = useDispatch();
  const currency: string | null = localStorage.getItem("currency");

  useEffect(() => {
    getAccountConfigPayInFull();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountConfigPayInFull = async () => {
    try {
      dispatch(setLoading(true));
      const res: any = await getAccountConfig({
        lender,
        accountId,
        borrowerId,
        type: "payInFull",
      });
      if (res) setConfigs(res.configs);
      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const saveAccountConfig = async (values: any) => {
    try {
      configs!.allowed = values.allowPayInFull;
      configs!.redirectionAllowed = values.redirectLink;
      configs!.redirectionLink = values.link;

      const reqBody = {
        type: "payInFull",
        configs,
      };

      dispatch(setLoading(true));

      await putAccountConfig({
        lender,
        accountId,
        borrowerId,
        type: "payInFull",
        body: reqBody,
      });

      dispatch(setLoadingWithDelay(false));
      notification("Configurations saved.", "success");
      dispatch(setOpenModal({ modalName: "pay_in_full", openModel: false }));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  return (
    <Formik
      initialValues={{
        allowPayInFull: configs ? configs.allowed : false,
        redirectLink: configs ? configs.redirectionAllowed : false,
        link: configs && configs.redirectionLink ? configs.redirectionLink : "",
      }}
      enableReinitialize={true}
      validationSchema={payInFullSchema}
      onSubmit={async (value: any) => {
        saveAccountConfig(value);
      }}
    >
      {({ errors }) => (
        <div className="container flex-col justify-center items-center gap-10 flex my-6">
          <div className="flex-col justify-center items-center gap-2.5 flex">
            <div className="flex-col justify-start items-center flex">
              <div className="text-neutral-700 text-xl font-semibold leading-[31px]">
                Amount
              </div>
              <div className="text-zinc-900 text-4xl font-bold leading-[47.88px]">
                {currency} {amount}
              </div>
            </div>
          </div>
          <Form className="container" id="pay_in_full">
            <div className="flex-col justify-center items-center gap-4 flex">
              <SwitchField
                label="Allow Pay in Full"
                name="allowPayInFull"
                horizontal={true}
              />
              <SwitchField
                label="Redirect Link"
                name="redirectLink"
                horizontal={true}
                redirect={true}
                showLinkDefault={configs ? configs.redirectionAllowed : false}
              />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default PayInFull;
