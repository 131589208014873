import { BaseRequest } from "../models/types/BaseRequest";

const BASE_URL: string | undefined = process.env.REACT_APP_DEV_CORE_BASE_URL;

export const createRequest = async ({
  path,
  body,
  method,
  additionalHeaders,
}: BaseRequest) => {
  const token = `Bearer ${localStorage.getItem("token")}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let headers: any = {
    "Content-Type": "application/json",
  };

  if (additionalHeaders) {
    headers = { ...headers, ...additionalHeaders };
  }

  if (token && path !== "borrowers/login") {
    headers = { ...headers, Authorization: token };
  }

  const res = await fetch(`${BASE_URL}/${path}`, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
    cache: "no-store",
  });

  return res;
};
