import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import utilReducer from "./slices/utilSlice";
import statusesReducer from "./slices/statusesSlice";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["util"],
};

const utilPersistConfig = {
  key: "util",
  storage,
  blacklist: ["loading", "modalName", "openModel"],
};

const reducers = combineReducers({
  auth: authReducer,
  statuses: statusesReducer,
  util: persistReducer(utilPersistConfig, utilReducer),
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: true,
});

export default store;
