import { useEffect, useState } from "react";
import TableaueEmbed from "./TableaueEmbed";
import { getTableauToken } from "../../../../data/api/reportsAPI";
import { CircularProgress } from "@mui/joy";

type Props = {};

const TrailReport = (props: Props) => {
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    getToken();
  }, []);
  const getToken = async () => {
    const res = await getTableauToken();
    setToken(res.jwtToken);
  };
  return token !== "" ? (
    <TableaueEmbed token={token} />
  ) : (
    <div className="h-screen w-full flex justify-center pt-10">
      <CircularProgress size="sm" color="primary" />
    </div>
  );
};

export default TrailReport;
