import { Form, Formik } from "formik";
import { paymentPlanSchema } from "../../../../data/validations/ValidationScemas";
import SwitchField from "../../../../components/formComponents/fieldComponents/SwitchField";
import HorizontalInputField from "../../../../components/formComponents/fieldComponents/HorizontalInputField";
import { useEffect, useState } from "react";
import {
  getAccountConfig,
  putAccountConfig,
} from "../../../../data/api/accountAPI";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setOpenModal,
} from "../../../../data/store/slices/utilSlice";
import notification from "../../../../utils/toast";
import { setLoadingWithDelay } from "../../../../data/store/thunk/utilThunk";

interface IPaymentPlan {
  amount?: number;
  accountId: string;
  lender: string;
  borrowerId: string;
}

interface IPaymentPlanConfig {
  allowed: boolean;
  redirectionLink: string;
  redirectionAllowed: boolean;
  firstInstallmentPercentage: number;
  changeAllowedMonths: number;
  firstInstallmentDateAllowed: boolean;
  firstInstallmentDaysAllowed: number;
  extendedInstallmentsAmount: number;
  extendedInstallmentsAllowed: boolean;
  extendedInstallmentsMonthsAllowed: number;
}

interface IformValues {
  allowPaymentPlan: boolean;
  redirectLink: boolean;
  link: string;
  allowedMonths: number;
  percentageFirstInstallment: number;
  firstInstallmentDateAllowed: boolean;
  firstInstallmentDaysAllowed: number;
  extendedInstallmentsAmount: number;
  extendedInstallmentsAllowed: boolean;
  extendedInstallmentsMonthsAllowed: number;
}

const PaymentPlan = ({
  lender,
  accountId,
  borrowerId,
  amount,
}: IPaymentPlan) => {
  const [configs, setConfigs] = useState<IPaymentPlanConfig | null>(null);
  const dispatch: any = useDispatch();
  const currency: string | null = localStorage.getItem("currency");

  useEffect(() => {
    getAccountConfigPaymentPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountConfigPaymentPlan = async () => {
    try {
      dispatch(setLoading(true));

      const res: any = await getAccountConfig({
        lender,
        accountId,
        borrowerId,
        type: "paymentPlan",
      });
      if (res) setConfigs(res.configs);

      dispatch(setLoadingWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  const saveAccountConfig = async (values: IformValues) => {
    try {
      configs!.allowed = values.allowPaymentPlan;
      configs!.redirectionAllowed = values.redirectLink;
      configs!.redirectionLink = values.link;
      // configs!.firstInstallmentPercentage = values.percentageFirstInstallment;
      configs!.changeAllowedMonths = values.allowedMonths;
      configs!.firstInstallmentDateAllowed = values.firstInstallmentDateAllowed;
      configs!.firstInstallmentDaysAllowed = values.firstInstallmentDaysAllowed;
      configs!.extendedInstallmentsAllowed = values.extendedInstallmentsAllowed;
      configs!.extendedInstallmentsAmount = values.extendedInstallmentsAmount;
      configs!.extendedInstallmentsMonthsAllowed =
        values.extendedInstallmentsMonthsAllowed;

      const reqBody = {
        type: "paymentPlan",
        configs,
      };

      dispatch(setLoading(true));
      await putAccountConfig({
        lender,
        accountId,
        borrowerId,
        type: "paymentPlan",
        body: reqBody,
      });
      dispatch(setLoadingWithDelay(false));
      notification("Configurations saved.", "success");
      dispatch(
        setOpenModal({
          modalName: "payment_plan",
          openModel: false,
        })
      );
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setLoadingWithDelay(false));
    }
  };

  return (
    <Formik
      initialValues={{
        allowPaymentPlan: configs ? configs.allowed : false,
        redirectLink: configs ? configs.redirectionAllowed : false,
        link: configs && configs.redirectionLink ? configs.redirectionLink : "",
        allowedMonths: configs ? configs.changeAllowedMonths : 0,
        percentageFirstInstallment: configs
          ? configs.firstInstallmentPercentage
          : 0,
        firstInstallmentDateAllowed:
          configs?.firstInstallmentDateAllowed || false,
        firstInstallmentDaysAllowed: configs?.firstInstallmentDaysAllowed || 0,
        extendedInstallmentsAllowed:
          configs?.extendedInstallmentsAllowed || false,
        extendedInstallmentsAmount: configs?.extendedInstallmentsAmount || 0,
        extendedInstallmentsMonthsAllowed:
          configs?.extendedInstallmentsMonthsAllowed || 0,
      }}
      enableReinitialize={true}
      validationSchema={paymentPlanSchema}
      onSubmit={async (value: IformValues) => {
        saveAccountConfig(value);
      }}
    >
      {({ errors, values }) => (
        <div className="container flex-col justify-center items-center gap-10 flex my-6">
          <div className="flex-col justify-center items-center gap-2.5 flex">
            <div className="flex-col justify-start items-center flex">
              <div className="text-neutral-700 text-xl font-semibold leading-[31px]">
                Amount
              </div>
              <div className="text-zinc-900 text-4xl font-bold leading-[47.88px]">
                {currency} {amount}
              </div>
            </div>
          </div>
          <Form className="container" id="payment_plan">
            <div className="flex-col justify-center items-center gap-4 flex">
              <SwitchField
                label="Allow Payment Plan"
                name="allowPaymentPlan"
                horizontal={true}
                errorText={errors.allowPaymentPlan}
              />
              <SwitchField
                label="Redirect Link"
                name="redirectLink"
                horizontal={true}
                redirect={true}
                showLinkDefault={configs ? configs.redirectionAllowed : false}
                errorText={errors.redirectLink}
              />
              <HorizontalInputField
                name="allowedMonths"
                label="Allowed Months"
                placeholder="Enter months here"
                type="number"
                errorText={errors.allowedMonths}
              />
              {/* <HorizontalInputField
                name="percentageFirstInstallment"
                label="First Installment Percentage"
                placeholder="Enter percentage"
                endIcon={<PercentIcon />}
              /> */}
              <SwitchField
                label="First Installment Date"
                name="firstInstallmentDateAllowed"
                subLabel="Max Days Allowed"
                subtext="How many days ahead allowed"
                subName="firstInstallmentDaysAllowed"
                isLink={false}
                subType="number"
                horizontal={true}
                horizontalBorder={true}
                redirect={true}
                showLinkDefault={
                  configs ? configs.firstInstallmentDateAllowed : false
                }
                errorText={errors.firstInstallmentDateAllowed}
              />
              <div className="w-full rounded-md border border-slate-300">
                <SwitchField
                  label="Extended Installments Allowed"
                  name="extendedInstallmentsAllowed"
                  horizontal={true}
                  horizontalBorder={false}
                  errorText={errors.extendedInstallmentsAllowed}
                />
                {values.extendedInstallmentsAllowed && (
                  <>
                    <HorizontalInputField
                      name="extendedInstallmentsMonthsAllowed"
                      label="Extended Installments Months Allowed"
                      placeholder="Enter months here"
                      border={false}
                      type="number"
                      errorText={errors.extendedInstallmentsMonthsAllowed}
                    />

                    <HorizontalInputField
                      name="extendedInstallmentsAmount"
                      label="Extended Installments Amount"
                      placeholder="Enter amount here"
                      border={false}
                      type="number"
                      errorText={errors.extendedInstallmentsAmount}
                    />
                  </>
                )}
              </div>
              {/* <div className="w-full h-12 px-4 py-2 rounded-md border border-slate-300 justify-start items-center gap-6 inline-flex">
                <div className="grow shrink basis-0 h-8 justify-start items-center flex">
                  <div className="grow shrink basis-0 text-zinc-900 text-base font-semibold leading-snug">
                    Reset Payment Plan
                  </div>
                </div>
                <div className="px-2 py-0.5 rounded-md justify-center items-center gap-1.5 flex">
                  <div className="text-sky-600 text-sm font-semibold leading-[14px]">
                    Reset
                  </div>
                </div>
              </div> */}
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default PaymentPlan;
