import { ICreateRequest } from "../models/types/axiosCreateRequest";
import { createRequest } from "./axios";

export const getLender = async () => {
  const lender = localStorage.getItem("lenderName");
  try {
    const path: string = `/lender/${lender}`;
    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res = await createRequest(reqObj);
    return res;
  } catch (error) {
    console.log(error);
  }
};
