/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
import { BorrowerAccountStatus } from "../../models/types/statuses/BorrowerAccountStatus";
import { InstallmentStatus } from "../../models/types/statuses/InstallmentStatus";
import { StatusType } from "../../models/types/statuses/StatusType";

export interface IStatusesSlice {
  borrowerAccountStatuses: BorrowerAccountStatus | null;
  installmentStatuses: InstallmentStatus | null;
  loanTypes: StatusType[] | null;
  paymentPlanStatuses: StatusType[] | null;
  scheduledPaymentStatuses: StatusType[] | null;
}

const initialState: IStatusesSlice = {
  borrowerAccountStatuses: null,
  installmentStatuses: null,
  loanTypes: null,
  paymentPlanStatuses: null,
  scheduledPaymentStatuses: null,
};

const convertToMap = (arr: any) => {
  const map = arr.reduce((acc: any, status: any) => {
    acc[status.name] = status.businessId;
    return acc;
  }, {});
  return map;
};

const statusesSlice = createSlice({
  name: "statuses",
  initialState: initialState,
  reducers: {
    setStatuses: (state, action) => {
      const {
        borrowerAccountStatuses,
        installmentStatuses,
        loanTypes,
        paymentPlanStatuses,
        scheduledPaymentStatuses,
      } = action.payload;

      state.borrowerAccountStatuses = convertToMap(borrowerAccountStatuses);
      state.installmentStatuses = convertToMap(installmentStatuses);
      state.loanTypes = convertToMap(loanTypes);
      state.paymentPlanStatuses = convertToMap(paymentPlanStatuses);
      state.scheduledPaymentStatuses = convertToMap(scheduledPaymentStatuses);
    },
  },
});

export const { setStatuses } = statusesSlice.actions;

export default statusesSlice.reducer;

export const borrowerAccountStatuses = (state: any) =>
  state.statuses.borrowerAccountStatuses;

export const installmentStatuses = (state: any) =>
  state.statuses.installmentStatuses;

export const loanTypes = (state: any) => state.statuses.loanTypes;

export const paymentPlanStatuses = (state: any) =>
  state.statuses.paymentPlanStatuses;

export const scheduledPaymentStatuses = (state: any) =>
  state.statuses.scheduledPaymentStatuses;
